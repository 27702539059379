import getLocale from './utils/localized/getLocale'

export const APP_ROUTES: any = {
  INDEX: '/',
  LOGIN: {
    QUERY_OBJECT: () => {
      return {
        pathname: '/login',
        query: {
          callbackUrl: typeof window !== 'undefined' && window.location.origin,
        },
      }
    },
  },
  SIGN_UP: {
    QUERY_OBJECT: () => {
      return {
        pathname: '/signup',
        query: {
          callbackUrl: typeof window !== 'undefined' && window.location.origin,
        },
      }
    },
  },
  PROFILE: {
    DEFAULT: '/profile/customer',
    FAVORITES: '/profile/favorites',
    SAVED: '/profile/saved',
  },
  PASSWORD: {
    RESET: '/password/reset',
    NEW: '/password/new',
  },
  DASHBOARD: '/dashboard',
  PRODUCTS: {
    BY_URL_KEY: (url_key = ':url_key') => `/${url_key}`,
  },
  FAVORITES: '/favorites',
  CHECKOUT: '/checkout',
  CART: '/cart',
  CONFIGURATOR: '/configurator',
  CHECKOUT_SUCCESS: '/checkout/success',
  404: '/404',
  500: '/500',
  RECEIVE_GIFT: '/inspiracje/odbierz-prezent',
  BREAST_HEALTH_DAY: '/pl/blog/europejski-dzien-walki-z-rakiem-piersi',
  CONTACT: '/contact',
  BLOG: '/blog',
  INFORMATION: '/information',
  INSPIRATION: '/inspiration',
  TERMS_AND_CONDITIONS: '/information/regulamin-sklepu-internetowego',
  PRIVACY_POLICY: '/information/polityka-prywatnosci',
  NEWSLETTER_REGULATIONS: '/information/regulamin-newslettera',
  BESTSELLERS: '/shop/recommended/bestsellers',
}

export const API_ROUTES = {
  CONFIGURATOR_ADD_TO_CART: {
    getByLocale: (locale: string) => {
      return `${locale}/rest/V1/idea07-productcreator/create`
    },
  },
}
